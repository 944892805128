<template>
	<div class="message_manage">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="标题:">
							<el-input v-model="msg_title" placeholder="请输入标题" clearable></el-input>
						</el-form-item>
						<el-form-item label="消息类型:">
							<el-select v-model="user_type" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-row class="left mb30">
				<el-col :span="24">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="add">添加</el-button>
				</el-col>
			</el-row>
			<el-table border :data="message_list" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column label="ID" prop="system_id" width="60"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="消息标题" prop="title" width="400px"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="消息类型" prop="user_type_name" width="120px"></el-table-column>
				<el-table-column label="是否停用">
					<template slot-scope="scope">
						<el-tag :type="scope.row.is_show == 1 ? 'success' : 'danger'" disable-transitions>{{scope.row.is_show_name}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="添加时间" prop="create_time" width="170"></el-table-column>
				<el-table-column label="操作" fixed="right" width="180">
					<template slot-scope="scope">
						<div>
							<el-button type="primary" size="mini" @click="update(scope.row)">修改</el-button>
							<el-button type="danger" size="mini" @click="remove(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
		<!-- 添加/更新 -->
		<el-dialog :title="title" width="800px" top="5vh" :visible.sync="addVisible">
			<el-form ref="addRef" :model="addForm" :rules="addRules" label-width="100px" class="left">
				<el-row>
					<el-col :span="22">
						<el-form-item label="消息标题:" prop="title">
							<el-input v-model="addForm.title" placeholder="请输入消息标题"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="消息类型:" prop="user_type">
							<el-select v-model="addForm.user_type" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item label="是否停用:" prop="is_show">
							<el-radio v-model="addForm.is_show" label="1">正常</el-radio>
							<el-radio v-model="addForm.is_show" label="2">停用</el-radio>
						</el-form-item>
					</el-col>
					<el-col :span="22">
						<el-form-item label="消息内容:" prop="content">
							<el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" accept="image/*" :show-file-list="false" :before-upload="beforeUpload" :http-request="getUploadUrl" style="height: 0px;"></el-upload>
							<quill-editor v-model="addForm.content" :options="editorOption" ref="QuillEditor" style="height: 320px; margin-bottom: 100px;"></quill-editor>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div>
				<el-button type="default" @click="addClose">取消</el-button>
				<el-button type="primary" @click="addSub">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import axios from 'axios'
	import {beforeUrl} from "@/api/request.js";
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Pagination from '@/components/Pagination/Pagination.vue'
	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
		['blockquote', 'code-block'], //引用，代码块
		[{
			'header': 1
		}, {
			'header': 2
		}], // 几级标题
		[{
			'list': 'ordered'
		}, {
			'list': 'bullet'
		}], // 有序列表，无序列表
		[{
			'script': 'sub'
		}, {
			'script': 'super'
		}], // 下角标，上角标
		[{
			'indent': '-1'
		}, {
			'indent': '+1'
		}], // 缩进
		[{
			'direction': 'rtl'
		}], // 文字输入方向
		[{
			'size': ['small', false, 'large', 'huge']
		}], // 字体大小
		[{
			'header': [1, 2, 3, 4, 5, 6, false]
		}], // 标题
		[{
			'color': []
		}, {
			'background': []
		}], // 颜色选择
		[{
			'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial']
		}], // 字体
		[{
			'align': []
		}], // 居中
		['clean'], // 清除样式,
		['link', 'image'], // 上传图片、上传视频
	]
	export default {
		components: {
			Treeselect,
			Pagination
		},
		data() {
			return {
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				options: [{
					value: '9',
					label: '全部'
				}, {
					value: '1',
					label: '渠道推广员'
				}, {
					value: '2',
					label: '经纪人'
				}, {
					value: '3',
					label: '服务人员'
				}, {
					value: '4',
					label: '门店'
				}, {
          value: '5',
          label: '城市合伙人'
        }, {
					value: '0',
					label: '客户'
				}],
				user_type: '9',
        msg_title: '',
				title: '添加角色',
				total: 0,
				message_list: [],
				addVisible: false,
				addForm: {
					system_id: '',
					title: '',
					user_type: '9',
					content: '',
					is_show: '1',
				},
				addRules: {
					title: [{
						required: true,
						message: '请输入消息标题',
						trigger: 'blur'
					}],
					user_type: [{
						required: true,
						message: '请选择消息类型',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入消息内容',
						trigger: 'blur'
					}],
				},
				editorOption: {
					placeholder: '请在这里输入',
					theme: 'snow', //主题 snow/bubble
					modules: {
						history: {
							delay: 1000,
							maxStack: 50,
							userOnly: false
						},
						toolbar: {
							container: toolbarOptions,
							handlers: {
								image: function(value) {
									if(value) {
										// 调用element的图片上传组件
										document.querySelector('.avatar-uploader input').click()
									} else {
										this.quill.format('image', false)
									}
								}
							}
						}
					}
				},
			}
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getMessageList();
			}
		},
		methods: {
			beforeUpload (file) {

      },
			async getUploadUrl(fileData){
				var url = 'index/imgUpload';
				var formData = new FormData();
				//如果存在文件
				if(fileData.file) {
					formData.append("file", fileData.file, fileData.file.name);
					if(timer != null) {clearTimeout(timer);}
					timer = setTimeout(() => {
						axios({
							url: url,
							headers: {
								"Content-Type": "multipart/form-data",
							},
							method: "post",
							data: formData,
						}).then(res => {
							console.log(res);
							// 获取富文本组件实例
					      	let quill = this.$refs.QuillEditor.quill
					      	// 如果上传成功
					      	if (res.status==200) {
					        	// 获取光标所在位置
			        			let length = quill.getSelection().index;
						        // 插入图片，res为服务器返回的图片链接地址
						        quill.insertEmbed(length, 'image', res.data)
						        // 调整光标到最后
						        quill.setSelection(length + 1)
					      	}
						}).catch(error => {
							that.$message.error('网络错误');
						})
					}, 300);
				}
			},
			getMessageList() {
				var url = 'message/message_list';
				let params = {
					title: this.msg_title,
					user_type: this.user_type,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.message_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//查询
			search() {
				this.getMessageList();
			},
			// 重置
			reset() {
				this.msg_title = '';
				this.user_type = '9';
				this.getMessageList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getMessageList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getMessageList();
			},
			//添加弹窗打开
			add() {
				this.title = '添加';
				this.addForm = {
					system_id: '',
					title: '',
					user_type: '9',
					content: '',
					is_show: '1',
				};
				this.addVisible = true;
			},
			update(item) {
				this.title = '编辑';
				this.addForm = {
					system_id: item.system_id,
					title: item.title,
					user_type: item.user_type,
					content: item.content,
					is_show: item.is_show,
				};
				this.addVisible = true;
			},
			//关闭添加弹窗
			addClose() {
				this.$refs.addRef.resetFields()
				this.addForm.content = ''
				this.addVisible = false
			},
			//确认添加
			addSub() {
				this.$refs.addRef.validate(value => {
					if(!value) return
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'message/addOrUpdate';
						let params = that.addForm;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.addVisible = false;
								that.$refs.addRef.resetFields();
								that.$message.success('操作成功');
								that.getMessageList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				})
			},
			//删除
			remove(item) {
				this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'message/deleteMsg';
				    	let params = {
				    		system_id: item.system_id
				    	};
						that.fd_post(url, params).then((res) => {
				    		if(res.status){
				    			that.$message.success("操作成功");
									if (that.message_list.length === 1 && this.queryInfo.pagenum > 1) {
										this.queryInfo.pagenum = this.queryInfo.pagenum - 1
									}
				    			that.getMessageList();
				    		}else{
				    			that.$message.error(res.msg);
				    		}
				        }).catch((err) => {
				            that.$message.error('网络错误');
				        });
					}, 300);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.message_manage {
		.left {
			text-align: left;
		}
		.mb30 {
			margin-bottom: 30px;
		}
	}
</style>